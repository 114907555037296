@import "../../../vars.scss";

.container {
  width: 100%;
  max-width: 900px;
  border-radius: 22px;
  border: 2px solid #8a8a8f;
  padding: 10px;
  background-color: #fff;
  cursor: text;
}
.textArea {
  width: 100%;
  height: 170px;
  font-size: 20px;
  padding: 10px 17px;
  resize: none;
  outline: none;
  border: none;
  @include font(20px, 400, 22px);
  color: black;
  &::placeholder {
    color: #8a8a8f;
  }
  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 30px;
  }

  &::-webkit-scrollbar-track {
    background: #dfdfd9;
    border-radius: 20px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c3c5;
    border-radius: 20px;
  }
}
