@import "../../../vars.scss";

.container {
  width: 100%;
  max-width: 900px;
  border-radius: 22px;
  border: 2px solid #8a8a8f;
  padding: 10px;
  background-color: #fff;
  cursor: text;
}
.input {
  width: 100%;
  height: 50px;
  font-size: 20px;
  padding: 10px 17px;
  padding-right: 60px;
  resize: none;
  outline: none;
  border: none;
  @include font(20px, 400, 22px);
  color: black;
  &::placeholder {
    color: #8a8a8f;
  }
  &:focus {
    outline: none;
  }
}
