@import "../../../vars.scss";

.chatItemWrapper {
  width: 100%;
  display: flex;
  padding: 10px;
}
.messageItemWrapperRight {
  justify-content: end;
}
.messageItem {
  max-width: 70%;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(102 248 102);
  transition: all;
  transition-duration: 0.5s;
  @include font(22px, 400, 26px);
  color: black;
  white-space: pre-wrap;
}
.messageItemLeft {
  background-color: rgb(214 214 235);
}
