@import "../../../vars.scss";

.button {
  width: 100%;
  border-radius: 25px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #188bf6;
  transition: all 0.25s ease-in-out 0s;
  @include font(22px, 600, 22px);

  cursor: pointer;
  &:hover {
    filter: brightness(125%);
  }
}
.disabled {
  cursor: not-allowed;
}
