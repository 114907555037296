@import "../../vars.scss";

.generateMassageBlockInnerWrapper {
  border: 2px solid #8a8a8f;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px 0px;
  min-height: 0px;
  transition: all 1s ease-in-out;
}
.generateMassageBlockInnerWrapperShow {
  min-height: 400px;
}

.generateMassageBlockInner {
  max-height: 100%;
  border-radius: 20px;
  min-height: 0px;
  overflow: auto;
  transition: all 1s ease-in-out;
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #dfdfd9;
    border-radius: 20px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c3c5;
    border-radius: 20px;
  }

  pre {
    white-space: pre-wrap;
    margin: 0;
    color: black;
    padding: 0 20px;

    @include font(20px, 400, 22px);
  }
}
.generateMassageBlockInnerVisibleScrollBar {
  &::-webkit-scrollbar {
    width: 6px;
  }
}
.generateMassageBlockInnerShow {
  min-height: 400px;
}
