@import "../../vars.scss";

.container {
  height: 100vh;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #dfdfd9;
  max-width: 900px;
  margin: 0 auto;
}
.chatBlock {
  flex-grow: 1;
  min-height: 600px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #dfdfd9;
    border-radius: 20px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c3c5;
    border-radius: 20px;
  }
}
.inputBlock {
  position: relative;
  width: 100%;
  max-width: 900px;
}

.sendMessageSvg {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}
