@import "../vars.scss";

.container {
  height: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #dfdfd9;
  max-width: 900px;
  margin: 0 auto;
}
.agreementBlock {
  @include font(18px, 400, 22px);
  color: black;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  padding-left: 40px;
}
.checkMarkBlock {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border: 2px solid #8a8a8f;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 2px solid black;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: rgb(4, 208, 4);
  }
}
.buttonsBlock {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: end;
  width: 100%;
  button {
    max-width: 50%;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.generateMassageBlock {
  position: relative;
  max-width: 900px;
  flex-grow: 1;
}
.copyBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e1e2e3;
  cursor: pointer;

  &:hover {
    background-color: #dfdfd9;
  }
}

.loadingSpinnerContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.loadingSpinnerText {
  position: relative;
  left: 15px;
  margin-top: 25px;
  @include font(24px, 400, 22px);
}
